<template>
    <b-dropdown
        id="user-menu"
        variant="link"
        class="m-2 user-menu"
        right
        :no-caret="true"
        :pressed="null"
        :menu-class="'user-menu dropdown-items'"
    >
        <template #button-content>
            <span class="menu-link ej-primary-extra-bold"
                >ACTIVIDADES
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#fff"
                >
                    <path d="M480-360 280-560h400L480-360Z" /></svg>
                </span>
        </template>
        <b-dropdown-group
            v-if="!$store.getters.getSession"
            id="dropdown-group-2"
        >
            <b-dropdown-item-button @click="$router.push('/tecnologia')"
                ><span class="text-white ej-primary-extra-bold"
                    >Tecnología</span
                ></b-dropdown-item-button
            >
            <b-dropdown-item-button @click="$router.push('/gaming')"
                ><span class="text-white ej-primary-extra-bold"
                    >Gaming</span
                ></b-dropdown-item-button
            >
            <b-dropdown-item-button @click="$router.push('/lab-emprendedores')"
                ><span class="text-white ej-primary-extra-bold"
                    >Lab. de emprendedores</span
                ></b-dropdown-item-button
            >
            <b-dropdown-item-button @click="$router.push('/ronda-de-negocios')"
                ><span class="text-white ej-primary-extra-bold"
                    >Ronda de Negocios</span
                ></b-dropdown-item-button
            >
            <b-dropdown-item-button @click="$router.push('/agtech')"
                ><span class="text-white ej-primary-extra-bold"
                    >agTech</span
                ></b-dropdown-item-button
            >
            <b-dropdown-item-button @click="$router.push('/socialtech')"
                ><span class="text-white ej-primary-extra-bold"
                    >socialTech</span
                ></b-dropdown-item-button
            >
            <b-dropdown-item-button @click="$router.push('/desafio-innovacion')"
                ><span class="text-white ej-primary-extra-bold"
                    >Desafío de innovación</span
                ></b-dropdown-item-button
            >
            <b-dropdown-item-button @click="$router.push('/turtech')"
                ><span class="text-white ej-primary-extra-bold"
                    >TurTech</span
                ></b-dropdown-item-button
            >
        </b-dropdown-group>
    </b-dropdown>
</template>

<script>
export default {
    name: "MenuButton",

    methods: {
        showModal() {
            this.$emit("show-modal");
        },
    },
};
</script>

<style>
.menu-link {
    display: inline-flex;
    align-items: center;
    gap: 5px; /* Espacio entre texto y SVG */
    color: #fff;
    text-decoration: none;
    position: relative;
}

.menu-link::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: rgb(36, 238, 108);
    position: absolute;
    bottom: -2px;
    right: 75%;
    transition: width 0.4s ease, right 0.4s ease;
}

.menu-link:hover::after,
.menu-link.active::after {
    width: 75%;
    right: 0;
}

.menu-link:hover {
    text-decoration: none;
}


.menu-icon svg {
    fill: #fff;
    transition: fill 0.4s ease;
}

.menu-link:hover svg {
    fill: rgb(36, 238, 108);
}

.dropdown-item {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    position: relative;
}

.dropdown-item::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: rgb(36, 238, 108);
    position: absolute;
    bottom: -2px;
    right: 75%;
    transition: width 0.4s ease, right 0.4s ease;
}

.dropdown-item:hover::after,
.dropdown-item.active::after {
    width: 75%;
    right: 0;
}

.dropdown-item:hover {
    text-decoration: none;
    background-color: transparent !important;
}

ul.dropdown-menu {
    background-color: var(--color-secondary-pink);
    border: 2px solid #fff;
}
</style>
