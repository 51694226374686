<template>
    <footer class="ej-footer py-5">
        <div class="container">
            <!--DESKTOP-->
            <div
                class="row align-items-center justify-content-center"
            >
                <div class="col-12 col-md-4">
                    <div class="d-flex justify-content-md-end justify-content-center">
                        <img
                            src="../../assets/logo-corner-footer.png"
                            alt="Logo Corner"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="ej-footer__social-media d-flex flex-row justify-content-md-end justify-content-center mt-5 mt-lg-0">
                        <div class="ej-footer__social-media__content mx-4">
                            <a
                                href="https://www.facebook.com/conertech23/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src="../../assets/logo-facebook.png"
                                    alt="Logo fb"
                                />
                            </a>
                        </div>
                        <div class="ej-footer__social-media__content mx-4">
                            <a
                                href="https://www.instagram.com/conertech/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src="../../assets/logo-instagram.png"
                                    alt="Logo ig"
                                />
                            </a>
                        </div>
                        <div class="ej-footer__social-media__content mx-4">
                            <a
                                href="https://www.tiktok.com/@coner.tech"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src="../../assets/logo-tiktok.png"
                                    alt="Logo tiktok"
                                />
                            </a>
                        </div>
                        <div class="ej-footer__social-media__content mx-4">
                            <a
                                href="https://www.twitch.tv/conertech2024"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src="../../assets/logo-twich.png"
                                    alt="Logo twich"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "HomeFooter",
};
</script>

<style scoped>
img:hover {
    cursor: pointer;
}
.ej-footer__social-media__content {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #636363;
}
.ej-footer__social-media__content:hover {
    cursor: pointer;
}
</style>
