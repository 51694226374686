import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        session: false,
        loading: true,
        emailVerified: false,
    },
    getters: {
        getSession(state) {
            return state.session;
        },
        getLoading(state) {
            return state.loading;
        },
        getEmailVerified(state) {
            return state.emailVerified;
        },
    },
    mutations: {
        setSession(state, session) {
            state.session = session;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setEmailVerified(state, setEmailVerified) {
            state.setEmailVerified = setEmailVerified;
        },
    },
    actions: {
        update(context, payload) {
            context.commit("setSession", payload);
        },
        loading(context, payload) {
            context.commit("setLoading", payload);
        },
        emailVerified(context, payload) {
            context.commit("setEmailVerified", payload);
        },
    },
});

export default store;
