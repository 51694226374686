<template>
    <b-overlay
        class="overlay-screen"
        :show="$store.getters.getLoading"
        rounded="sm"
    >
        <home-header></home-header>
        <div>
            <router-view />
        </div>
        <home-footer></home-footer>
    </b-overlay>
</template>

<script>
import HomeHeader from "@/components/Header/HomeHeader.vue";
import HomeFooter from "@/components/HomeFooter/HomeFooter.vue";

export default {
    components: {
        "home-header": HomeHeader,
        "home-footer": HomeFooter,
    },
    mounted() {
        setTimeout(() => {
            this.$store.dispatch("loading", false);
        }, 1000);
    },
};
</script>

<style>
.overlay-screen {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
</style>
