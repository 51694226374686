import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/session";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueMask from "v-mask";
import VueMaterial from "vue-material";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/js/bootstrap.js";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "@/assets/main.css";


import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

// Registra todas las reglas
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});


Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMaterial);
Vue.use(VueMask);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
