<template>
    <div>
        <b-nav
            class="d-flex align-items-center ej-header-bg p-3 px-lg-5 py-lg-4"
        >
            <div
                class="nav-bar-items w-100 d-flex justify-content-between justify-content-xl-center align-items-center"
            >
                <div>
                    <router-link to="/"
                        ><img
                            class="ej-logo-header"
                            src="../../assets/logo-header.png"
                            alt="Logo tech corner"
                    /></router-link>
                </div>
                <!-- MOBILE -->
                <div class="dropdown d-flex d-xl-none bg-red">
                    <button
                        class="btn btn-secondary btn-hamburguer"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <img
                            src="../../assets/pngwing.com.png"
                            alt="Menu hamburguesa"
                            class="hamburguer-icon"
                        />
                    </button>
                    <div
                        class="dropdown-menu ej-dropdown"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <ul
                            class="d-flex flex-column align-items-end mb-0 w-100 pr-4"
                        >
                            <li class="d-flex flex-column">
                                <router-link
                                    to="/"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Inicio</router-link
                                >
                                <div class="under-line"></div>
                            </li>
                            <li>
                                <router-link
                                    to="/tecnologia"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Tecnología</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/gaming"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Gaming</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/lab-emprendedores"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Lab. de emprendedores</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/ronda-de-negocios"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Ronda de Negocios</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/agtech"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >agTech</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/socialtech"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >socialTech</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/desafio-innovacion"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Desafio de Innovación</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/turtech"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >TurTech</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/last-expo"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Edición anterior</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    to="/contacto"
                                    class="text-white ej-primary-extra-bold text-center"
                                    exact
                                    exact-active-class="active"
                                    >Contacto</router-link
                                >
                            </li>
                        </ul>
                    </div>
                </div>

                <!--DESKTOP-->
                <ul
                    class="d-lg-flex d-none align-items-center justify-content-end mb-0 w-100 ml-5 pl-5"
                >
                    <li class="d-flex flex-column">
                        <router-link
                            to="/"
                            class="text-white ej-primary-extra-bold text-center"
                            exact
                            exact-active-class="active"
                            >Inicio</router-link
                        >
                        <div class="under-line"></div>
                    </li>
                    <li class="text-white ej-primary-extra-bold text-center">
                        <menu-button @show-modal="showModal" />
                    </li>
                    <li>
                        <router-link
                            to="/last-expo"
                            class="text-white ej-primary-extra-bold text-center"
                            exact
                            exact-active-class="active"
                            >Edición anterior</router-link
                        >
                    </li>
                    <li>
                        <router-link
                            to="/contacto"
                            class="text-white ej-primary-extra-bold text-center"
                            exact
                            exact-active-class="active"
                            >Contacto</router-link
                        >
                    </li>
                    <div class="ej-footer__social-media__content mx-4">
                        <a
                            href="https://www.facebook.com/conertech23/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                src="../../assets/logo-facebook.png"
                                alt="Logo fb"
                            />
                        </a>
                    </div>
                    <div class="ej-footer__social-media__content mx-4">
                        <a
                            href="https://www.instagram.com/conertech/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                src="../../assets/logo-instagram.png"
                                alt="Logo ig"
                            />
                        </a>
                    </div>
                    <div class="ej-footer__social-media__content mx-4">
                        <a
                            href="https://www.tiktok.com/@coner.tech"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                src="../../assets/logo-tiktok.png"
                                alt="Logo tiktok"
                            />
                        </a>
                    </div>
                    <div class="ej-footer__social-media__content mx-4">
                        <a
                            href="https://www.twitch.tv/conertech2024"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                src="../../assets/logo-twich.png"
                                alt="Logo twich"
                            />
                        </a>
                    </div>
                </ul>
            </div>
        </b-nav>
    </div>
</template>

<script>
import MenuButton from "../MenuButton/MenuButton.vue";

export default {
    components: {
        "menu-button": MenuButton,
    },
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        showModal() {
            this.$refs.modal.$refs.modal.show();
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
};
</script>

<style scoped>
a {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    position: relative;
    text-decoration: none;
}

a::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: rgb(36 238 108);
    position: absolute;
    bottom: -2px;
    right: 75%;
    transition: width 0.4s ease, right 0.4s ease;
}

.active::after {
    width: 75%;
    right: 0%;
    transform: none;
}
.ej-header-bg {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1039;
}

@media (min-width: 1200px) {
    .ej-header-bg {
        position: fixed;
    }
}
.ej-logo-header {
    width: 250px;
}

li {
    margin-bottom: 10px;
    list-style-type: none;
    padding: 7px;
}

li:hover a::after {
    width: 75%;
    right: 0;
    list-style-type: none;
}

a:hover {
    text-decoration: unset;
}

ul {
    display: flex;
}

ul li {
    list-style-type: none;
    margin-bottom: 0;
}

ul li a {
    text-transform: uppercase;
    list-style-type: none;
}

.ej-dropdown {
    background: var(--color-primary-pink);
    border: 2px solid #fff;
    width: 350px;
    border-radius: 20px 0 20px 20px;
    left: -20px;
}
.dropdown-menu {
    transform: translate3d(-288px, 52px, 0px) !important;
}
.hamburguer-icon {
    width: 40px;
    height: 40px;
}
.btn-hamburguer {
    background: transparent;
    border: none;
}

.ej-footer__social-media__content img {
    height: 35px;
    width: 35px;
}
</style>
