/* eslint-disable no-undef */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "HomeView",
        component: () => import("../views/Home/Home.vue"),
        meta: { title: "Inicio" },
    },
    {
        path: "/tecnologia",
        name: "TecnologiaView",
        component: () => import("../views/Tecnologia/Tecnologia.vue"),
        meta: { title: "Tecnologia" },
    },
    {
        path: "/gaming",
        name: "GamingView",
        component: () => import("../views/esports/Esports.vue"),
        meta: { title: "Gaming" },
    },
    {
        path: "/empleabilidad",
        name: "EmpleabilidadView",
        component: () => import("../views/Empleability/Empleability.vue"),
        meta: { title: "Empleabilidad" },
    },
    {
        path: "/last-expo",
        name: "LastExpoView",
        component: () => import("../views/LastExpo/LastExpo.vue"),
        meta: { title: "Edición Anterior" },
    },
    {
        path: "/lab-emprendedores",
        name: "LabEmprendedoresView",
        component: () =>
            import("../views/LabEmprendedores/LabEmprendedores.vue"),
        meta: { title: "Gaming" },
    },
    {
        path: "/ronda-de-negocios",
        name: "RondaDeNegociosView",
        component: () => import("../views/RondaDeNegocios/RondaDeNegocios.vue"),
        meta: { title: "Ronda de negocios" },
    },
    {
        path: "/agtech",
        name: "AgTechView",
        component: () => import("../views/AgTech/AgTech.vue"),
        meta: { title: "agTech" },
    },
    {
        path: "/socialtech",
        name: "SocialTechView",
        component: () => import("../views/SocialTech/SocialTech.vue"),
        meta: { title: "socialTech" },
    },
    {
        path: "/turtech",
        name: "TurTechView",
        component: () => import("../views/TurTech/TurTech.vue"),
        meta: { title: "TurTech" },
    },
    {
        path: "/desafio-innovacion",
        name: "DesafioInnovacionView",
        component: () =>
            import("../views/DesafioInnovacion/DesafioInnovacion.vue"),
        meta: { title: "Desafio Innovación" },
    },
    {
        path: "/contacto",
        name: "ContactoView",
        component: () => import("../views/Contact/Contact.vue"),
        meta: { title: "Contacto" },
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

router.afterEach((to) => {
    const baseTitle = "Coner Tech 2024";
    const sectionTitle = to.meta.title || "Sección";
    document.title = `${baseTitle} | ${sectionTitle}`;
});

export default router;
